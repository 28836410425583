import { Divider, Grid, InputLabel } from '@material-ui/core';

import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { find as _find, findIndex as _findIndex } from 'lodash';

import { InputField, SelectField } from '../../../forms';
import { 
  cardTypeDb, 
  lookupBasicDb, 
  srvFrecuencysDb, 
  statusDb, 
  weekDays 
} from '../../../models/db';
import { Box } from '../../shared/MaterialUI';
import DatePickerField from '../../../forms/DatePickerField';
import { WizardStyles } from '../../../assets/css';
import AppButton from '../../forms/AppButton';
import { 
  MarketService, 
  PickItemService, 
  PickService, 
  UserService 
} from '../../../services';
import AppHelper from '../../../helpers/AppHelper';
import { useUI } from '../../../app/context/ui';
import { Utils, FormatWeekYear } from '../../../helpers';

const marketService = new MarketService();
const userService = new UserService();
const pickService = new PickService();
const pickItemService = new PickItemService();

const Lookup1 = () => {
  const {handleSubmit, setFieldValue} = useFormikContext();
  const [tag, setTag] = useState(null);
  const [parentDb, setParentDb] = useState([]);
  const [marketDb, setMarketDb] = useState([]);
  const [sourceDb, setSourceDb] = useState([]);
  const [sourceOptionDb, setSourceOptionDb] = useState([]);
  const [csrsDb, setCsrsDb] = useState([]);

  const { values } = useFormikContext();

  const {snackbarUI} = useUI();

  const wizardStyle = WizardStyles();
  const handleChangeBasic = (event) => {
    const value = event.target.value;
    if (value > 0) {
      const tag = _find(lookupBasicDb, {'value': value});
      setTag(tag.tag);
      setFieldValue('parentBasic', '');
      setFieldValue('field', tag.field);
      setParentDb(renderSwitch(value));
    }
  };

  const renderSwitch = (param) => {
    switch (param) {
      case 4:
        return statusDb;
      case 5:
        return weekDays;
      case 13:
        return marketDb;
      case 16:
        return srvFrecuencysDb;
      case 17:
        return csrsDb;
      case 18:
        return cardTypeDb;
      default:
        return [];
    }
  }

  const getListMarket = useCallback(async () => {
    try {
      marketService.getAccessToken();
      const r1 = await marketService.list();
      const arr = Utils.updateArrayObjectItem(r1.data, [] );
      setMarketDb(arr);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [snackbarUI]);

  const getListSource = useCallback(async () => {
    try {
      pickService.getAccessToken();
      const r1 = await pickService.listSearch('filter[group_id]=1&sort=name');
      const arr = Utils.updateArrayObjectItem(r1.data, [] );
      setSourceDb(arr);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [snackbarUI]);

  const handleChangeSource = async (event) => {
    try {
      pickItemService.getAccessToken();
      const value = event.target.value;
      if (value > 0) {
        const r1 = await pickItemService.listSearch(`filter[picklist_id]=${value}&sort=name`);
        const arr = Utils.updateArrayObjectItem(r1.data, [] );
        setSourceOptionDb(arr);
      }
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }    
  };

  const getListUser = useCallback(async () => {
    try {
      userService.getAccessToken();
      const r1 = await userService.list('?role=customer-service&status=1');
      const arrCsr = Utils.updateArrayObjectItem(r1.data.data,
        [{value: null, label: '-- Select CSR --'}], 'id', 'fullName');
      setCsrsDb(arrCsr);
      await getUserMe(arrCsr);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  },[snackbarUI])

  const getUserMe = useCallback(async (arrCsr = []) => {
    try {
      const r1 = await userService.me();
      const obj = Utils.updateObjectKey(r1.data, 'id', 'fullName');

      if (_findIndex(arrCsr, function (o) {
        return o.value === r1.data.id;
      }) === -1) {
        arrCsr.push(obj);
      }
      setCsrsDb(arrCsr);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  },[snackbarUI])

  useEffect(() => {
    (async function init() {
      await getListMarket();
    })();
  }, [getListMarket]);

  useEffect(() => {
    (async function init() {
      await getListUser();
    })();
  }, [getListUser]);

  useEffect(() => {
    (async function init() {
      await getListSource();
    })();
  }, [getListSource]);

  useEffect(() => {
    setFieldValue('searchBasic', '');
    setFieldValue('fields', []);
  }, [setFieldValue]);

  return (
    <div style={{width: '100%'}}>
      <Divider/>
      <Box my={2}/>
      <Grid container alignItems="center" spacing={2}>
        <Grid container item xs={12}>
          <SelectField
            name="searchBasic"
            data={lookupBasicDb}
            onClick={handleChangeBasic}
            autoFocus
            fullWidth
            placeholder={'-- Select --'}
          />
        </Grid>
        <Grid container item xs={12}>
          <InputLabel className={wizardStyle.title}>
            Search:
          </InputLabel>
        </Grid>
        <Grid container item xs={12}>
          {(() => {
            const inputProps = values.searchBasic === 14 ? { inputComponent: FormatWeekYear } : {};

            switch (tag) {
              case 'text_field':
                return <InputField
                  name="parentBasic"
                  InputProps={inputProps} 
                  autoFocus
                  fullWidth
                />
              case 'drop_down':
                return <SelectField 
                  name="parentBasic" 
                  data={parentDb} 
                  placeholder={'-- Select --'} 
                  autoFocus 
                  fullWidth
                />;
              case 'calendar_select':
                return <DatePickerField
                  disableToolbar
                  name="parentBasic"
                  format="MM/dd/yyyy"
                  maxDate={new Date('2050/12/31')}
                  autoFocus
                  fullWidth
                />;
              case 'calendar_range':
                return <div className={wizardStyle.wrapperRangeDate}>
                  <SelectField
                    name="about"
                    data={sourceDb}
                    onClick={handleChangeSource}
                    autoFocus
                    fullWidth
                    placeholder={'-- Select --'}
                  />
                  <br/>
                  <br/>
                  <SelectField
                    name="aboutOption"
                    data={sourceOptionDb}
                    autoFocus
                    fullWidth
                    placeholder={'-- Select --'}
                  />
                  <br/>
                  <br/>
                  <DatePickerField
                    disableToolbar
                    name="startDate"
                    format="MM/dd/yyyy"
                    maxDate={new Date('2050/12/31')}
                    placeholder="Please select start date"
                    autoFocus
                    fullWidth
                  />
                  <br/>
                  <DatePickerField
                    disableToolbar
                    name="endDate"
                    format="MM/dd/yyyy"
                    maxDate={new Date('2050/12/31')}
                    placeholder="Please select end date"
                    autoFocus
                    fullWidth
                  />
                </div>
              default:
                return null;
            }
          })()}
        </Grid>
        <Grid container item xs={12}>
          <AppButton
            onPress={handleSubmit}
            label={'Search'}
            type={'main'}
            color={'primary'}
          />
        </Grid>
      </Grid>
    </div>
  );
}
export default Lookup1;
