class AppCalendar {

  static getLastWeekOfFebruary(year) {
    let lastDay = new Date(year, 2, 0);
    let lastMonday = new Date(lastDay);

    while (lastMonday.getDay() !== 1) {
      lastMonday.setDate(lastMonday.getDate() - 1);
    }

    let lastFriday = new Date(lastMonday);
    lastFriday.setDate(lastMonday.getDate() + 4);

    if (lastFriday.getMonth() === 1) {
      return lastMonday
    } else {
      lastMonday.setDate(lastMonday.getDate() - 7);
      lastFriday.setDate(lastFriday.getDate() - 7);
    }

    return lastMonday
  }

  static calculateStartDateByYear(year) {
    let date = new Date(year, 2, 1);
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() + 1);
    }
    if (date.getDate() > 3) {
      date.setDate(date.getDate() - 7);
    }
    return date;
  }

  static getMowYear() {
    let year = new Date().getFullYear();
    let monday = new Date();
    monday.setDate(monday.getDate() - monday.getDay() + 1);
    let weekday = this.getLastWeekOfFebruary(year);
    if (monday.toISOString().split('T')[0] < weekday.toISOString().split('T')[0]) {
      year = year - 1;
    }

    return year;
  }

  static getDateLastFriday() {
    const t = new Date().getDate() + (6 - new Date().getDay() - 1);
    const lastFriday = new Date();
    lastFriday.setDate(t);
    return lastFriday.getMonth() ?? new Date().getMonth();
  }
}

export default AppCalendar;
