import { makeStyles } from '@material-ui/core/styles';

export const ChartStyles = makeStyles((theme) => ({
  chartsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  chartBox: {
    backgroundColor: '#f1f1f1',
    borderRadius: '8px',
    minHeight: '295px',
    height: 'auto',
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '45%',
    },
    padding: '20px 22px',
  },
  metricsBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '15%',
    [theme.breakpoints.down('lg')]: {
      width: '18%',
    },
    padding: '24px',
    backgroundColor: '#f1f1f1',
    borderRadius: '8px',
    justifyContent: 'space-between',
    '& .metricsTitle': {
      fontWeight: 700,
      fontFamily: 'Roboto',
    },
  },
  numberMetric: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
      color: '#10AA39',
    },
  },
  circleIcon: {
    width: '45px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50px',
  },
  linearProgress: {
    marginTop: '8px',
    height: '8px',
    backgroundColor: 'rgba(36, 38, 35, 0.3)',
    borderRadius: '4px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#80BB57',
    },
  },
  linearProgressDanger: {
    marginTop: '8px',
    height: '8px',
    backgroundColor: 'rgba(36, 35, 35, 0.3)',
    borderRadius: '4px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FF0000',
    },
  },
}));

export const SideChartStyles = makeStyles(() => ({
  sideChart: {
    '& .lineChart': {
      width: '100%',
      marginBottom: '16px',
      borderRadius: '8px',
    },
    '& .sideMetrics': {
      margin: '0 auto',
      backgroundColor: '#f1f1f1',
      borderRadius: '8px',
      width: '100%',
      height: '180px',
      padding: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      '& .numbers': {
        fontSize: '44px',
        fontWeight: 700,
        textAlign: 'center',
      },
      '&.sideOne': {
        marginBottom: '20px',
        backgroundColor: 'rgba(229, 244, 255, 1)',
        '& span': {
          backgroundColor: '#C0CFE9',
        },
        '& .numbers': {
          color: '#5887D6',
        },
      },
      '&.sideTwo': {
        backgroundColor: 'rgba(221, 255, 239, 1)',
        '& span': {
          backgroundColor: '#B4F4C5',
        },
        '& .numbers': {
          color: '#10AA39',
        },
      },
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50px',
        height: '50px',
        borderRadius: '50px',
      },
    },
  },
  sideTitles: {
    color: '#0B0C0E',
    fontWeight: 700,
    fontSize: '18px',
    textAlign: 'center',
  },
}));
