import React, {
  useCallback, useEffect, useMemo, useRef, useContext, useState
} from 'react';

import { Form, Formik, useFormikContext } from 'formik';
import {
  assign as _assign, isArray as _isArray, replace as _replace,
  toNumber as _toNumber,
} from 'lodash';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Box, Divider, Grid, InputLabel } from '@material-ui/core';

import { useUI } from '../../../app/context/ui';
import { updateProperty } from '../../../app/store/mm/customerSlice';
import { WizardStyles } from '../../../assets/css';
import { CheckboxField, InputField } from '../../../forms';
import AutoSave from '../../../forms/AutoSave';
import CheckboxArrayField from '../../../forms/CheckboxArrayField';
import AppHelper from '../../../helpers/AppHelper';
import ValidationFields from '../../../models/validationFields';
import { BusinessLogicService, CustomerService, LogicService } from '../../../services';
import { PreviousCustomerContext } from '../EditCustomer';
import NumberFormatCustom from "../../../helpers/NumberFormatCustom";
import FormatCoordinate from "../../../helpers/FormatCoordinate";

const Step3 = (props) => {
  const { blockUI, snackbarUI } = useUI();
  const formikProperty = useRef();
  const [bagging, setBagging] = useState('');
  const customerService = useMemo(() => new CustomerService(), []);
  const businessLogicService = useMemo(() => new BusinessLogicService(), []);
  const logicService = useMemo(() => new LogicService(), []);
  const [autoSave, setautoSave] = useState(true);
  const [formIsFocused, setFormIsFocused] = useState(false);
  const {
    formField: {
      lotSize,
      turfSize,
      lotReview,
      useCornerLot,
      mowCut,
      communityAnswer,
      crew,
      backyardAnswer,
      lat,
      lng
    },
  } = props;

  const dispatch = useDispatch();
  const { bagrass } = useContext(PreviousCustomerContext);
  const { formikRef } = props;
  const { values: formValues } = useFormikContext();
  const { id, taxRate, srvFrecuency } = formValues;
  const wizardStyle = WizardStyles();

  const [formLot, setFormLot] = useState({
    lotSize: formValues.lotSize,
    turfSize: formValues.turfSize,
    isBagging: null
  });

  const initialValues = {
    srvFrecuency: formValues.srvFrecuency,
    lotSize: formValues.lotSize,
    turfSize: formValues.turfSize,
    lotReview:
      (!formValues.lotReview ||
        formValues.lotReview.toString().toLowerCase() === 'false' ||
        formValues.lotReview.toString().toLowerCase() === 'true')
        ? ''
        : formValues.lotReview,
    useCornerLot: formValues.useCornerLot,
    mowCut: !_isArray(formValues.mowCut)
      ? JSON.parse(formValues.mowCut)
      : _assign([], formValues.mowCut),
    communityAnswer: formValues.communityAnswer,
    crew: formValues.crew,
    mapsco: formValues.mapsco,
    mapA: formValues.mapA,
    mapscoGrid: formValues.mapscoGrid,
    backyardAnswer: formValues.backyardAnswer,
    costService: formValues.costService,
    totalMowing: formValues.totalMowing,
    calculatedTax: formValues.calculatedTax,
    serviceDate: formValues.serviceDate,
    week: formValues.week,
    billingLotSize: formValues.billingLotSize,
    lat: formValues.lat,
    lng: formValues.lng,
  };

  const validationSchema = Yup.object({
    srvFrecuency: ValidationFields.srvFrecuency(),
    lotSize: Yup.number()
      .required(`${lotSize.requiredErrorMsg}`)
      .test('lotSize', `${lotSize.invalidErrorMsg}`, (val) => val && val > 0),
    turfSize: Yup.number()
      .nullable()
      .max(16000, "Lot size must be greater than 0 and can't be exceeded 16000"),
    mowCut: ValidationFields.mowCut(),
    lat: Yup.string()
      .nullable()
      .matches(/^\d*(?:\.\d+)?$/, 'Please enter a valid positive number.'),
    lng: Yup.string()
      .matches(/^-\d*(?:\.\d+)?$/, 'Please enter a valid negative number.'),
  });

  const onBlurTurf = (e) => {
    const value = e.target.value > 0 ? e.target.value : formValues.lotSize;
    const isTurf = e.target.value > 0;
      (async function init() {
        await getFindLotSize(value, isTurf);
      })();
  };

  const valiteSrvFrequency = () => {
    let resp = false;
    if (srvFrecuency === 3 || srvFrecuency === 4) {
      resp = true;
    }

    return resp;
  }

  const onBlurLot = (e) => {
    const value = formValues.turfSize > 0 ? formValues.turfSize : e.target.value;
    const isTurf = formValues.turfSize > 0;
    if (!isTurf) {
        (async function init() {
          await getFindLotSize(value, isTurf);
        })();
    }
  };

  const getFindLotSize = useCallback(
    async (value, turfSize = true, isLoad = false) => {
      try {
        blockUI.current.open(true);
        businessLogicService.getAccessToken();
        value = value === '' ? 0 : value;
        const r1 = turfSize
            ? await businessLogicService.findTurfSizeCustomer(value, id)
            : await businessLogicService.findLotSizeCustomer(value, id);
        if (!isLoad) {
          await setServiceCost(
            JSON.parse(r1.data.serviceFrequency),
            formValues.srvFrecuency,
            r1.data.id
          );
        }
        setBagging(includeNameSize(r1.data.name));
        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(true);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockUI, businessLogicService, formValues, snackbarUI, formLot]
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        blockUI.current.open(true);
        customerService.getAccessToken();

        await customerService.update(values, id);

        dispatch(updateProperty(values));
        if (formikProperty.current.initialValues.mowCut !== values.mowCut) {
          await setUpdatePrice(taxRate, values.costService);
        }

        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockUI, customerService, initialValues, id, dispatch, snackbarUI]
  );

  const setServiceCost = useCallback(
    async (srv, key, id) => {
      if (formikProperty.current) {
        const costService = _toNumber(_replace(srv[`f${key}`], '$', ''));
        //formikProperty.current?.setFieldValue('costService', costService);
        //formikProperty.current?.setFieldValue('billingLotSize', id);
        dispatch(updateProperty({'costService': costService}));
        dispatch(updateProperty({'billingLotSize': id}));
        await setUpdatePrice(taxRate, costService);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taxRate, formValues, formLot]
  );

  const setUpdatePrice = useCallback(
    async (taxRate, costService) => {
      if (costService && formValues.srvFrecuency) {
        logicService.getAccessToken();
        let find = '';
        find += `costService=${Number(costService)}`;
        find += `&lotSize=${formLot.lotSize}`;
        find += `&turfSize=${_toNumber(formLot.turfSize)}`;
        find += `&taxRate=${_toNumber(taxRate)}`;
        find += `&srvFrequency=${formValues.srvFrecuency}`;
        find += `&isBagging=${formLot.isBagging}`;
        find += `&businesslogicId=${_toNumber(formValues.billingLotSize)}`;

        const { data: dataBaggrass } = await logicService.proccessBaggrass(find);

        //formikProperty.current?.setFieldValue('subtotalMowing', dataBaggrass.newSubtotal);
        //formikProperty.current?.setFieldValue('totalMowing', dataBaggrass.total);
        //formikProperty.current?.setFieldValue('calculatedTax', dataBaggrass.tax);

        dispatch(updateProperty({'subtotalMowing': _toNumber(dataBaggrass.newSubtotal)}));
        dispatch(updateProperty({'totalMowing': _toNumber(dataBaggrass.total)}));
        dispatch(updateProperty({'calculatedTax': _toNumber(dataBaggrass.tax)}));
      }
    },
    [formValues, formikRef, logicService, formLot]
  );

  const handleChangeLotReview = (e) => {
    formikProperty.current?.setFieldValue('lotReview', e.target.value.toUpperCase());
  }

  const includeNameSize = (name) => {
    const nameLotSizes = ['Xtra large', 'Very large', 'Jumbo', 'Enormous'];
    return nameLotSizes.includes(name);
  }

  const handleOnChangeCheckbox = async (values) => {
    setFormLot({
      ...formLot,
      isBagging:values.includes('BagGrass')
    });
    setautoSave(false);
    blockUI.current.open(true);
    customerService.getAccessToken();
    await customerService.update({"mowCut":values}, id);
    const { data: dataFields } = await customerService.read(
        id
    );
    dispatch(updateProperty({'totalMowing': _toNumber(dataFields.totalMowing)}));
    dispatch(updateProperty({'subtotalMowing': _toNumber(dataFields.subtotalMowing)}));
    dispatch(updateProperty({ 'mowCut': values }));
    formikProperty.current?.setFieldValue('mowCut', dataFields.mowCut);
    setTimeout(() => {
      blockUI.current.open(false);
      setautoSave(true);
    }, 500);
  }

  const handleFormLot = ({target}) => {
    const {name, value} = target;
    setFormLot({
      ...formLot,
      [name]:_toNumber(value)
    });
  }

  useEffect(() => {
    const {lotSize, turfSize, mowCut} = formValues;
    setFormLot({
      ...formLot,
      lotSize:_toNumber(lotSize),
      turfSize:_toNumber(turfSize),
      isBagging:mowCut.includes('BagGrass')
    });
  }, []);

  useEffect(() => {
    (async function init() {
      const value = formValues.turfSize > 0 ? formValues.turfSize : formValues.lotSize;
      const turfSize = formValues.turfSize > 0;
      await getFindLotSize(value, turfSize, true);
    })();
  }, []);

  useEffect(() => {
    formikProperty.current?.setFieldValue('backyardAnswer', formValues.backyardAnswer);
    formikProperty.current?.setFieldValue('communityAnswer', formValues.communityAnswer);
    formikProperty.current?.setFieldValue('mowCut', formValues.mowCut);
  }, [formValues.backyardAnswer, formValues.communityAnswer, formValues.mowCut]);

  return (
    <>
      <Formik
        innerRef={formikProperty}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (

          <Form autoComplete='off' onFocus={() => setFormIsFocused(true)} onBlur={() => setFormIsFocused(false)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Lot size:</InputLabel>
                <InputField
                  name={lotSize.name}
                  onBlur={onBlurLot}
                  onKeyUp={handleFormLot}
                  InputProps={{ inputComponent: NumberFormat }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Turf size:</InputLabel>
                <InputField
                  name={turfSize.name}
                  onBlur={onBlurTurf}
                  onKeyUp={handleFormLot}
                  InputProps={{ inputComponent: NumberFormat }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Lot review:</InputLabel>
                <InputField
                  fullWidth
                  name={lotReview.name}
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'center', textTransform: "uppercase" },
                    minLength: 0,
                    maxLength: 5
                  }}
                  onChange={handleChangeLotReview}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Corner lot:</InputLabel>
                <CheckboxField name={useCornerLot.name} label={useCornerLot.label} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Mow area:</InputLabel>
                <CheckboxArrayField
                  row
                  name={mowCut.name}
                  onCheckboxChange={handleOnChangeCheckbox}
                  disabled={(valiteSrvFrequency() || bagrass) || bagging}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Community code:</InputLabel>
                <InputField
                  name={communityAnswer.name}
                  fullWidth
                />
                <Box mt={2} />
                <InputLabel className={wizardStyle.label}>Gate Code:</InputLabel>
                <InputField
                  name={backyardAnswer.name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Crew:</InputLabel>
                <InputField
                  name={crew.name}
                  InputProps={{ inputComponent: NumberFormat }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>{lat.label}</InputLabel>
                <InputField
                  name={lat.name}
                  placeholder={lat.placeholder}
                  InputProps={{ inputComponent: FormatCoordinate }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>{lng.label}</InputLabel>
                <InputField
                  name={lng.name}
                  placeholder={lng.placeholder}
                  InputProps={{ inputComponent: NumberFormat }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                {autoSave && (
                    <>
                      <AutoSave flag={autoSave} debounceMs={500} id={id} isFocused={formIsFocused} />
                    </>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Step3;
